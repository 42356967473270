<template>
  <section class="w-11/12 flex flex-col item-center rounded-lg p-10 bg-white shadow-[0_0_15px_0_rgba(0,0,0,15%)]"
    v-show="testStart">
    <Transition name="slide-fade" v-for="(test, index) in allTests" :key="test.name">
      <testSection @next="$emit('next')" :section_num="index" :current_section="currentTest" :title="test.name"
        :category="test.category" :countD="testStart">
        <!-- <div class="qst_stepper" v-show="qst > 0"></div> -->
        <Transition v-for="(question, id) in test.questions_list" :key="test.name + 'qst' + id" name="slide-fade">
          <QstBox :custom="test.category === 'Custom'" :qst_category="question?.category" :current_qst="qst"
            :qst_number="id" :qst_nbr_to_add="question.question_number" :questions_nbr="test.questions_list?.length"
            :qst_question="question.question" :qst_code="question.code" :qst_title="question.title"
            :qst_options="qst.options || {}" :qst_description="question.description" :testName="test.name"
            :start="qst === index + 1" />
        </Transition>
      </testSection>
    </Transition>
  </section>
</template>

<script>
import testSection from "../components/section.vue";
// import testQuestion from "../components/question.vue";
import { useAssessementStore } from "@/store/assessements";
//import WebcamComponent from "./WebcamComponent.vue";

import QstBox from "./QstBox.vue";
export default {
  name: "testContainer",
  components: { testSection, /* testQuestion , */ QstBox },
  props: {
    testStart: { type: Boolean, default: false },
    currentTest: { type: Number },
    // qst:{type:Number}
  },
  data() {
    return {
      allTests: [],
    };
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  computed: {
    qst() {
      console.log("QST:", this.assessementStore.question);
      return this.assessementStore.question;
    },
  },
  async mounted() {
    // await this.getAssessments();
    await this.assessementStore.fetchAssessments();
    this.allTests = this.assessementStore.assessements;
    console.log("ALL the TESTS: " + this.allTests.length);
    this.allTests.forEach((test) => console.log("the sest", test));
    this.allTests.forEach((test) => {
      this.assessementStore.addTests(test.name);
      if (test.category !== "Custom") {
        this.assessementStore.durationTests.push(test.duration * 60 * 1000);
      } else {
        test.questions_list.forEach((quest) => {
          this.assessementStore.durationQuestions.push(quest.time * 60 * 1000);
        });
      }
    });
    console.log("Duration tests table:", this.assessementStore.durationTests);
    console.log("All the tests ", this.assessementStore.assessements);
    console.log(
      "Duration QUESTions table:",
      this.assessementStore.durationQuestions
    );
    this.assessementStore.questionTime = this.assessementStore.durationTests[0];
    this.assessementStore.timeRemaining =
      this.assessementStore.durationTests[0];
    console.log(
      "Time remaining table:",
      this.assessementStore.questionTime,
      this.assessementStore.timeRemaining
    );
  },
};
</script>

<style lang="scss" scoped>
.testcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
