<!-- App.vue -->
<template>
  <div class="container-webcam relative">
    <div v-if="isLoading"
      class="absolute top-0 left-0 w-full h-full bg-[#00000038] flex flex-col justify-center items-center">
      <LoaderComponent />
      <h4 class="text-center text-white mt-5" style="font-weight: 400; font-size: 18px">
        Detecting your face,<br />
        please wait...
      </h4>
    </div>
    <video ref="video" id="camDisplay" width="400" height="300" autoplay class="w-full h-full"></video>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import * as faceapi from "face-api.js";
//import axios from "axios";
import { useAssessementStore } from "../store/assessements";
import LoaderComponent from "./LoaderComponent.vue";
import { throttle } from "lodash"; // Import throttle from lodash

export default {
  name: "WebCam",
  components: {
    LoaderComponent,
  },
  props: ["setupWebcam"],
  data() {
    return {
      devices: [],
      isLoading: true,
      selectedDevice: null,
      camSwitcher: false,
      currentStream: null,
      videoSuccess: false,
      canvas: null,
      video: null,
      imageUrl: "",
      avatar_url: "",
      first_name: "",
      second_name: "",
      cheater_id: "",
      count: 0,
    };
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.video = this.$refs.video;

    if (this.assessementStore.selectedDevice) {
      this.getUserMedia();
    }

    if (!this.setupWebcam) {
      document.addEventListener("visibilitychange", this.handleTabChange);
      document.addEventListener(
        "fullscreenchange",
        this.handleFullscreenChange
      );
      window.addEventListener("mouseleave", this.handleMouseLeave);
    }
  },
  beforeDestroy() {
    if (!this.setupWebcam) {
      document.removeEventListener("visibilitychange", this.handleTabChange);
      window.removeEventListener("mouseleave", this.handleMouseLeave);
      document.removeEventListener(
        "fullscreenchange",
        this.handleFullscreenChange
      );
    }
  },
  methods: {
    handleMouseLeave() {
      console.log("Mouse left the window");
      this.assessementStore.mouseExited = true;
      this.assessementStore.isCheater = true;

    },
    handleTabChange() {
      if (document.hidden) {
        this.assessementStore.tabExited = true;
        this.assessementStore.exitedCount++;
        this.assessementStore.isCheater = true;

      }
    },
    handleFullscreenChange() {
      const isFullscreen = document.fullscreenElement !== null;
      if (!isFullscreen) {
        this.assessementStore.fullScreenExited = true;
        this.assessementStore.isCheater = true;

      }
    },
    getUserMedia() {
      const constraints = {
        video: {
          deviceId: this.assessementStore.selectedDevice
            ? { exact: this.assessementStore.selectedDevice }
            : undefined,
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.video = this.$refs.video; // Ensure the video element is referenced correctly
          if (this.video && "srcObject" in this.video) {
            this.video.srcObject = stream;
          } else if (this.video) {
            this.video.src = window.URL.createObjectURL(stream);
          } else {
            console.error("Video element not found");
            return;
          }
          this.video.onloadedmetadata = () => {
            this.video.play();
            this.isLoading = false; // Set isLoading to false when the video metadata is loaded
            if (this.setupWebcam) {
              this.initFaceDetectionSetup();
            } else {
              this.initFaceDetection();
            }
          };
        })
        .catch((err) => {
          console.error("Error accessing media devices:", err);
        });
    },
    async getFaceDescriptorFromImage() {
      const img = await faceapi.fetchImage(
        `data:image/png;base64, ${this.assessementStore.avatar_url}`
      );
      const detection = await faceapi
        .detectSingleFace(img)
        .withFaceLandmarks()
        .withFaceDescriptor();

      // console.log("Detection Result:", detection);

      if (detection) {
        return detection.descriptor;
      } else {
        // Handle the case where no face is detected, e.g., throw an error, log a message, etc.
        throw new Error("No face detected in the provided image");
      }
    },
    async initFaceDetection() {
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      } catch (error) {
        console.error("Error loading models:", error);
      }

      console.log("passed models");

      const referenceDescriptor = await this.getFaceDescriptorFromImage();

      const displaySize = {
        width: this.video.width,
        height: this.video.height,
      };
      faceapi.matchDimensions(this.canvas, displaySize);

      let twoFacesDetectedStart = null;
      let nonMatchingFaceDetectedStart = null;

      const detectFaces = throttle(async () => {
        const detections = await faceapi
          .detectAllFaces(this.video)
          .withFaceLandmarks()
          .withFaceDescriptors();

        const now = Date.now();

        if (detections.length > 1) {
          if (!twoFacesDetectedStart) {
            twoFacesDetectedStart = now;
          } else if (now - twoFacesDetectedStart >= 5000) {
            console.log("Cheating attempt detected!");
            this.takeCheatingScreenshots();
            this.assessementStore.isCheater = true;
            console.log("two faces has been detected for 10 seconds straight");

            twoFacesDetectedStart = null;
          }
        } else {
          twoFacesDetectedStart = null;
        }

        const resizedDetections = faceapi.resizeResults(
          detections,
          displaySize
        );

        this.canvas
          .getContext("2d")
          .clearRect(0, 0, this.canvas.width, this.canvas.height);

        resizedDetections.forEach((detection) => {
          const faceDescriptor = detection.descriptor;

          const distance = faceapi.euclideanDistance(
            referenceDescriptor,
            faceDescriptor
          );

          const threshold = 0.6;

          const isMatch = distance < threshold;

          this.assessementStore.allowFace = isMatch;

          const box = detection.detection.box;
          const drawOptions = {
            label: isMatch
              ? `${this.assessementStore.first_name} ${this.assessementStore.second_name}`
              : `Not allowed`,
          };
          drawOptions.color = isMatch ? "green" : "red";

          const drawBox = new faceapi.draw.DrawBox(box, drawOptions);
          drawBox.draw(this.canvas);

          if (!isMatch && detections.length == 1) {
            if (!nonMatchingFaceDetectedStart) {
              nonMatchingFaceDetectedStart = now;
            } else if (now - nonMatchingFaceDetectedStart >= 20000) {
              console.log("Non-matching face detected!");

              // Take a screenshot
              this.takeCheatingScreenshots();
              this.assessementStore.isCheater = true;

              nonMatchingFaceDetectedStart = null;
            }
          } else {
            nonMatchingFaceDetectedStart = null;
          }
        });

        this.assessementStore.face_detected = detections.length > 0;
      }, 500); // Throttle function to run at most once every 500ms

      // Run the detection function at an interval
      setInterval(detectFaces, 100); // Adjust interval as needed

    },
    async initFaceDetectionSetup() {
      // console.log("executed now");
      // console.log("the video:", this.video);
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      } catch (error) {
        console.error("Error loading models:", error);
      }

      // console.log("passed models");

      const referenceDescriptor = await this.getFaceDescriptorFromImage();

      // console.log("passed description func");

      const displaySize = {
        width: this.video.width,
        height: this.video.height,
      };
      faceapi.matchDimensions(this.canvas, displaySize);

      setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(this.video)
          .withFaceLandmarks()
          .withFaceDescriptors();

        const resizedDetections = faceapi.resizeResults(
          detections,
          displaySize
        );

        this.canvas
          .getContext("2d")
          .clearRect(0, 0, this.canvas.width, this.canvas.height);

        resizedDetections.forEach((detection) => {
          const faceDescriptor = detection.descriptor;

          // Compare the detected face descriptor with the reference descriptor
          const distance = faceapi.euclideanDistance(
            referenceDescriptor,
            faceDescriptor
          );

          // Adjust the threshold as needed
          const threshold = 0.6;

          // Check if the distance is below the threshold for a match
          const isMatch = distance < threshold;

          this.assessementStore.allowFace = isMatch ? true : false;

          const box = detection.detection.box;
          const drawOptions = {
            label: isMatch
              ? `${this.assessementStore.first_name} ${this.assessementStore.second_name}`
              : `Not allowed`,
          };
          drawOptions.color = isMatch ? "green" : "red";
          this.isLoading = false;
          // Create DrawBox with drawOptions
          const drawBox = new faceapi.draw.DrawBox(box, drawOptions);
          drawBox.draw(this.canvas);
        });
      }, 100);
    },
    takeScreenshots() {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = this.video.width;
      tempCanvas.height = this.video.height;

      const tempContext = tempCanvas.getContext("2d");
      tempContext.drawImage(
        this.video,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );

      // Convert the canvas data to a Data URL with JPEG format
      const screenshotDataUrl = tempCanvas.toDataURL("image/jpeg", 0.5); // 0.8 is the quality, adjust as needed
      // Push the new screenshot data URL into the array
      this.assessementStore.screenShots.push(screenshotDataUrl);
      console.log(
        "Here is the screen shots array: " + this.assessementStore.screenShots
      );
    },
    takeCheatingScreenshots() {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = this.video.width;
      tempCanvas.height = this.video.height;

      const tempContext = tempCanvas.getContext("2d");
      tempContext.drawImage(
        this.video,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );

      // Convert the canvas data to a Data URL with JPEG format
      const screenshotDataUrl = tempCanvas.toDataURL("image/jpeg", 0.5); // Adjust quality as needed

      // Create an object with the image and the current date
      const capturedPhoto = {
        image: screenshotDataUrl,
        date: new Date().toISOString(), // Capture the current date and time
      };

      // Push the new screenshot data URL into the array
      this.assessementStore.capturedPhotos.push(capturedPhoto);
      console.log("Captured Photos Array: ", this.assessementStore.capturedPhotos);
    },

  },
};
</script>

<style lang="scss" scoped>
.container-webcam {
  display: flex;
  justify-content: center;
  align-items: center;
}

canvas {
  position: absolute;
}

.loadingCam {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
}
</style>
