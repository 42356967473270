<template>
  <div class="window">
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />

    <main class="container">
      <Transition v-if="show" name="slide-fade">
        <LoaderComponent v-if="animation" />

        <WelcomeCard v-if="passed == 1" class="first_page" :key="this.jobPosition" :key1="this.key1" :key2="this.key2"
          :key3="this.key3" :key4="this.key4" :key5="this.key5" :company="this.company" :jobPosition="this.jobPosition"
          @start="start" />
        <WelcomeCardpasstest v-else class="first_page" :key="this.company" :key1="this.key1" :key2="this.key2"
          :key3="this.key3" :key4="this.key4" :key6="this.key6" :company="this.company"
          :jobPosition="this.jobPosition" />

      </Transition>
      <Transition v-else name="slide-fade">
        <!-- <section class="second_page w-4/5 px-20 py-12 shadow-[0_0_4px_2px_rgba(0,0,0,15%)]" v-show="step == 1"> -->
        <TestBox v-if="step == 1" button-text="Next" :handleClick="next" :isBtnLoading="isLoading"
          inside-div-class="second_page flex flex-col justify-center items-center " added-class="w-11/12">
          <!-- <span>Personal Information</span> -->
          <form action="POST" @submit.prevent ref="form">
            <div class="input_group2">
              <div class="input_group">
                <label for="first_name" class="required">First Name</label>
                <input required type="text" id="first_name" v-model="first_name" name="first_name" />
                <span v-if="requiredField.first_name" class="err_msg">{{
      requiredField.first_name
    }}</span>
              </div>
              <div class="input_group">
                <label for="last_name" class="required">Last Name</label>
                <input required type="text" id="last_name" v-model="last_name" name="last_name" />
                <span v-if="requiredField.last_name" class="err_msg">{{
      requiredField.last_name
    }}</span>
              </div>
            </div>
            <div class="input_group2">
              <div class="input_group">
                <label for="email" class="required">Email</label>
                <input type="email" id="email" required v-model="email" name="email" />
                <span v-if="requiredField.email" class="err_msg">{{
      requiredField.email
    }}</span>
              </div>
              <div class="input_group">
                <label for="phone" class="required">Phone Number</label>
                <input required type="tel" id="phone" v-model="phone" name="phone" />
                <span v-if="requiredField.phone" class="err_msg">{{
      requiredField.phone
    }}</span>
              </div>
            </div>
            <div class="input_group2">
              <div class="input_file">
                <input required accept="image/png" type="file" value="Upload Photo" placeholder="Upload Photo"
                  name="avatar" id="avatar" @change="handleAvatarUpload" />
                <span v-if="requiredField.avatar" class="err_msg">{{
      requiredField.avatar
    }}</span>
              </div>
            </div>
            <FaceIdWarning :isVisible="avatarWarning" @close="avatarWarning = false" />
          </form>
          <LoadingAI :isLoading="isLoading" />
        </TestBox>
        <!-- </section> -->
      </Transition>
      <Transition name="slide-fade">
        <TestBox v-if="step == 2" button-text="Get started" :handleClick="next"
          inside-div-class="third_page flex flex-col justify-center items-center " added-class="w-11/12">
          <GuidesRules />
        </TestBox>
      </Transition>
      <Transition name="slide-fade">
        <TestBox v-if="step == 3" button-text="Get started" :handleClick="next"
          inside-div-class="third_page flex flex-col justify-center items-center " added-class="w-2/5"
          :disableBtn="assesmentSetup">
          <AssessmentSetup />
        </TestBox>
      </Transition>
      <Transition name="slide-fade">
        <TestBox v-if="step == 4" button-text="Next" :handleClick="next" :disableBtn="!allowedCamera"
          inside-div-class="fouth_page flex flex-col justify-center items-center " added-class="w-11/12">
          <CameraSetup :setupWebcam="false" />
        </TestBox>
        <!-- <section class="fourth_page page_width" v-show="step == 41">
          <span>&#x2714;</span>
          <span>Everything is set up and we're ready to go</span>
          <span>Best Of Luck!</span>
        </section> -->
      </Transition>
      <Transition name="slide-fade">
        <!-- <TestBox
          v-if="step == 5"
          button-text="Next"
          :handleClick="submitScreeners"
          inside-div-class="fouth_page flex flex-col justify-center items-center "
          added-class="w-11/12"
        > -->
        <div class="w-full" v-if="step == 5">
          <ScreenersContainer :nextSection="() => next()" :nextStep="() => step++" />
        </div>
        <!-- </TestBox> -->
        <!-- <section class="fourth_page page_width" v-show="step == 41">
          <span>&#x2714;</span>
          <span>Everything is set up and we're ready to go</span>
          <span>Best Of Luck!</span>
        </section> -->
      </Transition>
      <Transition>
        <testContainer :testStart="step == 6" :currentTest="assessementStore.test" :qst="assessementStore.question"
          @next="
      next_qst(
        assessementStore?.assessements[assessementStore.test]
          ?.questions_list?.length
      )
      " />
      </Transition>
      <Transition name="slide-fade">
        <TestBox v-if="step == 7" button-text="Finish" :handleClick="handleRating"
          inside-div-class="third_page flex flex-col justify-center items-center " added-class="w-3/5">
          <FeedBack :handleFeedBack="handleFeedBack" :addRating="addRating" />
        </TestBox>
      </Transition>
      <Transition name="slide-fade">
        <TestBox v-if="step == 8" button-text="Submit" :handleClick="() => {
      this.step++;
      assessementStore.submitNewTalent;
    }
      " inside-div-class=" " added-class="w-3/5">
          <NewTalent />
        </TestBox>
      </Transition>
      <Transition name="slide-fade">
        <section v-if="this.assessementStore.projectId == '656dd5e2a26850987a567af8' &&
      this.score > 70
      " class="share_page page_width" v-show="step == 9">
          <ShareResults :nextSection="nextSection" />
        </section>
      </Transition>
      <Transition name="slide-fade">
        <section class="share_page page_width" v-show="this.assessementStore.projectId == '656dd5e2a26850987a567af8' &&
        this.score > 70
        ? step == 10
        : step == 9
          ">
          <span style="font-size: 72px; text-align: center; color: #2196f3">
            Thank you!
          </span>
        </section>
      </Transition>
    </main>
  </div>
</template>

<script>
import testContainer from "../components/assessementcontainer.vue";
import FaceIdWarning from "../components/FaceIdWarning.vue";
import LoadingAI from "../components/LoadingAI.vue";
// import Cookies from "js-cookie";
import LoaderComponent from "./LoaderComponent.vue";
// import CandidateResult from "./CandidateResult.vue";
// import SideBar from "./SideBar.vue";
import axios from "axios";
import { useAssessementStore } from "@/store/assessements";
import ToastNotification from "./ToastNotification.vue";
import ShareResults from "./ShareResults.vue";
import TestBox from "./TestBox.vue";
import GuidesRules from "./GuidesRules.vue";
import AssessmentSetup from "./AssessmentSetup.vue";
import CameraSetup from "./cameraSetup.vue";
import WelcomeCard from "./WelcomeCard.vue";
import WelcomeCardpasstest from "./WelcomeCardpasstest.vue";
import FeedBack from "./FeedBack.vue";
import NewTalent from "./NewTalent.vue";
import ScreenersContainer from "./screeners/ScreenersContainer.vue";

export default {
  name: "assessementTest",
  components: {
    // CandidateResult,
    // SideBar,
    WelcomeCard,
    FaceIdWarning,
    ScreenersContainer,
    LoadingAI,
    NewTalent,
    FeedBack,
    CameraSetup,
    AssessmentSetup,
    GuidesRules,
    TestBox,
    testContainer,
    ToastNotification,
    ShareResults,
    LoaderComponent,
    WelcomeCardpasstest,
  },
  props: {
    candidateProfile: { type: Object },
  },

  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },

  data() {
    return {
      isLoading: false,
      avatar_url: "",
      testName: "",
      show: true,
      testNum: -1,
      qstList: false,
      userAlreadyPassed: false,
      isVisible: false,
      bgc: "",
      message: "",
      requiredField: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        avatar: "",
      },
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      avatar: "",
      starOver: 0,
      rating: 0,
      key1: "",
      key2: "",
      key3: "",
      key4: "",
      key5: "",
      key6: "",
      passed: 1,
      animation: false,
      step: 1,
      disabled: false,
      isChecked: false,
      qst: 0,
      logged: false,
      section: false,
      allTests: [],
      feedback: "",
      score: "",
      avatarWarning: false,
      company: "",
      jobPosition: "",
    };
  },
  computed: {
    testInfos() {
      return this.assessementStore.metaInfos;
    },
    initials() {
      return (
        this.first_name.charAt(0) + this.last_name.charAt(0)
      ).toUpperCase();
    },
    full_name() {
      return this.first_name + " " + this.last_name;
    },
    assesmentSetup() {
      return (
        this.assessementStore.english == "" &&
        this.assessementStore.disability == ""
      );
    },
    allowedCamera() {
      return this.assessementStore.allowFace;
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.key1 = urlParams.get("email");
    this.key2 = urlParams.get("date");
    this.checkWelcomeCardStatus();
  },
  methods: {
    nextSection() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkWelcomeCardStatus() {
      const hasSeenWelcomeCard = localStorage.getItem("hasSeenWelcomeCard");
      if (hasSeenWelcomeCard) {
        //this.show = false;
      }
    },
    start() {
      window.scrollTo({ top: 0, behavior: "smooth" });

      this.show = false;
      // localStorage.setItem('hasSeenWelcomeCard', 'true');
    },
    handleAvatarUpload(event) {
      this.avatar = event.target.files[0];
      // Check if the avatar meets the criteria
      //  this.checkAvatar();
    },
    async checkAvatar() {
      if (!this.avatar) {
        this.errorMessage = 'Please select an image file.';
        return;
      }

      try {
        let fileToUpload;

        // Check if the file is already a PNG
        // if (this.avatar.type === 'image/png') {
        //   fileToUpload = this.avatar; 
        //  } else {
        // Convert the image to PNG format if it's not already PNG
        fileToUpload = await this.convertToPNG(this.avatar);
        // }

        const formData = new FormData();
        formData.append('avatar', fileToUpload); // Use the appropriate file

        const response = await axios.post('https://server.go-platform.com/face-detection/check-avatar', formData, {
          withCredentials: true
        });

        console.log('Face detected?', response.data.detected);
        this.avatarWarning = !response.data.detected;
      } catch (error) {
        console.error('Error uploading image:', error);
        this.errorMessage = 'There was an error processing the image.';
        this.avatarWarning = true;
      }
    },

    // Method to convert the image to PNG format
    convertToPNG(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas size to the image dimensions
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to a PNG blob
            canvas.toBlob((blob) => {
              if (blob) {
                const pngFile = new File([blob], `${file.name.split('.')[0]}.png`, {
                  type: 'image/png'
                });
                resolve(pngFile);
              } else {
                reject(new Error('Error converting image to PNG.'));
              }
            }, 'image/png');
          };

          img.onerror = () => reject(new Error('Error loading image.'));
        };

        reader.onerror = () => reject(new Error('Error reading file.'));
        reader.readAsDataURL(file);
      });
    },
    /* async checkAvatar() {
       if (!this.avatar) return;
 
       try {
         // Load the face-api models
         await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
         await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
         await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
 
         // Convert uploaded image to HTMLImageElement
         const img = await faceapi.bufferToImage(this.avatar);
 
         const detection = await faceapi
           .detectSingleFace(img)
           .withFaceLandmarks()
           .withFaceDescriptor();
 
         // Check if a face is detected
         if (detection) {
           // Face detected
           console.log("Face detected:", detection);
 
           // Do something with the detection, if needed
         } else {
           // No face detected
           console.log("No face detected in the provided image");
           this.avatarWarning = true;
         }
       } catch (error) {
         // Handle any errors that occur during face detection
         console.error("Error during face detection:", error);
         this.avatarWarning = true;
       }
     },*/
    async submitForm() {
      await this.checkAvatar();
      if (this.avatarWarning) {
        this.isLoading = false;
        return;
      }

      this.assessementStore.fetchAssessments().then(() => {
        if (this.email !== this.assessementStore.userEmail) {
          this.userAlreadyPassed = true;
          this.isLoading = false;
          this.message =
            "Please enter the email address you received the assessment at!";
          this.bgc = "red";
          this.isVisible = true;
          // console.log({ visibility: this.isVisible });
          setTimeout(() => {
            this.isVisible = false;
          }, 5000);
          return;
        } else {
          try {
            const candidateData = {
              First_name: this.first_name,
              Last_name: this.last_name,
              Email: this.email,
              Phone: this.phone,
              projectId: this.assessementStore.projectId,
            };
            this.assessementStore.setFullName(this.first_name, this.last_name);
            this.assessementStore.setUser(this.email);
            this.isLoading = true;
            // this.assessementStore.userLogged()
            var formdata = new FormData();
            const candidateJSON = JSON.stringify(candidateData);
            formdata.append("candidate", candidateJSON);
            formdata.append("Avatar", this.avatar);
            var requestOptions = {
              method: "POST",
              body: formdata,
              redirect: "follow",
            };

            fetch(
              "https://server.go-platform.com/candidates/post",
              requestOptions
            )
              .then((response) => {
                // console.log(response.status)
                if (response.status == 401) {
                  this.userAlreadyPassed = true;
                  this.message = "Please enter a valid email address";
                  this.bgc = "red";
                  this.isVisible = true;
                  this.isLoading = false;
                  // console.log({ visibility: this.isVisible });
                  setTimeout(() => {
                    this.isVisible = false;
                  }, 5000);
                  return;
                }
                return response.text();
              })
              .then((result) => {
                if (!result) return;
                this.step++;
                this.isLoading = false;
                const resultObject = JSON.parse(result);
                if (resultObject.logged) this.assessementStore.userLogged();
                this.disabled = true;
                // this.logged = resultObject.logged;
                // this.$emit("logged_candidate", {
                //   initials: this.initials,
                //   full_name: this.full_name,
                //   logged: this.logged,
                // });
              })
              .then(() => {
                this.assessementStore.getAvatar();
              });
          } catch (error) {
            console.error(error);
            this.isLoading = false;
          }
        }
      });
    },
    disable() {
      this.disabled = false;
    },
    SubmitTalent() { },
    handleFeedBack(feedBack) {
      this.feedback = feedBack;
    },
    addRating(rating) {
      switch (rating) {
        case "No":
          this.rating = 1;
          break;

        case "Somewhat":
          this.rating = 3;
          break;

        case "Yes":
          this.rating = 5;
          break;
        default:
          this.rating = 0;
          break;
      }
    },

    async next() {
      if (!this.section) {
        if (this.step === 1) {
          this.isLoading = true;
          this.disabled = false;

          //this.submitForm();
          if (!this.$refs.form.checkValidity()) {
            this.requiredField = {
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
            };
            for (let i = 0; i < this.$refs.form.elements.length; i++) {
              const input = this.$refs.form.elements[i];
              if (input.nodeName === "INPUT" && !input.checkValidity()) {
                this.requiredField[input.name] = input.validationMessage;
                this.isLoading = false;
              }
            }
            // this.step--;
          } else {
            console.log("submiting form");
            await this.submitForm();
          }
          //this.isLoading = false;
        } else {
          this.step++;
          console.log("executing full screen ");
        }
        if (this.step === 2) {
          this.disabled = true;
        }
        // if (this.step === 6 /* && this.assessementStore.question > 0 */) {
        //   console.log('sttep 66')
        //   this.qstList = false
        //   // console.log('answewr', this.assessementStore.answer)
        //   // this.startTimer = !this.startTimer;
        // }
      }
      if (this.step === 6) {
        this.assessementStore.showAssessmentNameInNavbar = true;
        this.assessementStore.requestFullScreenOnClick();
        console.log(
          "STARTING THE ASSESMENTTTTTTTTTTTSSSSSSSSSSSSSSSSSSSSSSSSSS"
        );
        // this.testName= this.testInfos[this.testNum].name
        // this.testNum++
        // this.assessementStore.nextTest()
        //this.assessementStore.nextQst()// this.qst++;
        // console.log(
        //   "count",
        //   this.assessementStore.assessements[this.assessementStore.test]
        //     .questions_list.length
        // );
        // await this.assessementStore.fetchAssessments();
        console.log(
          "-------------------======================-------------------------"
        );
        // const alltest = this.assessementStore.assessements;
        // this.assessementStore.nextTest();
        this.assessementStore.resetPreparation();
        this.assessementStore.countDown();
        console.log(
          "-------------------======================-------------------------"
        );

        console.log({
          HERETERTERTERTEDFSADASDFDF:
            this.testInfos[this.assessementStore.test],
        });
        this.next_qst(this.testInfos[this.assessementStore.test].qstCount);
        // this.next_qst(
        //   this.assessementStore.assessements[this.assessementStore.test]
        //     .questions_list.length
        // );
        this.section = true;
        this.qstList = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleRating() {
      this.step++;
      try {
        let evalutaion = JSON.stringify({
          rating: this.rating,
          feedback: this.feedback,
          candidateEmail: this.assessementStore.userEmail,
          projectId: this.assessementStore.projectId,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://server.go-platform.com/AssessmentTest/candidateRating",
          headers: {
            "Content-Type": "application/json",
          },
          data: evalutaion,
        };

        axios
          .request(config)

          .then(() => {
            return;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    next_qst(length) {
      this.assessementStore.nextQst();
      if (this.assessementStore.question === length + 1)
        if (this.assessementStore.test === this.testInfos.length - 1) {
          this.step++;
          this.assessementStore.testStarted = false;

          // this.submitResult();
        } else {
          // this.assessementStore.resetPreparation()
          this.assessementStore.resetQst();
          this.assessementStore.showAssessmentNameInNavbar = false;
          this.assessementStore.nextTest();
          this.assessementStore.testStarted = false;
        }
      if (this.step === 7 /* && this.assessementStore.question > 0 */) {
        this.submitResult();
        this.qstList = false;
        // this.startTimer = !this.startTimer;
      }
    },
    submitResult() {
      try {
        // this.assessementStore.stopTime();
        this.disabled = !this.disabled;

        // Include constants into the data body
        let dataBody = {
          answers: this.assessementStore.answer,
          exitedTab: this.assessementStore.tabExited,
          fullScreenExited: this.assessementStore.fullScreenExited,
          mouseExited: this.assessementStore.mouseExited,
          exitCount: this.assessementStore.exitedCount,
          screens: this.assessementStore.screenShots,
        };

        let answers = JSON.stringify(dataBody);
        console.log(
          { answers },
          "------------------------------------------==============================----------------------------------------------------------------"
        );
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          //https://server.go-platform.com/
          url: "https://server.go-platform.com/AssessmentTest/evaluateCandidate",
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: answers,
        };

        axios
          .request(config)

          .then((response) => {
            this.score = response.data.score;
            this.disabled = !this.disabled;
            if (this.assessementStore.isCheater == true) {
              this.assessementStore.createCheater();
            }
          })
          .catch((error) => {
            console.log(error);
            this.disabled = !this.disabled;
          });
        // var requestOptions = {
        //   method: "POST",
        //   body: answers,
        //   redirect: "follow",
        // };
        // console.log("submiting answer", answers);
        // fetch(
        //   "https://server.go-platform.com/AssessmentTest/evaluateCandidate",
        //   requestOptions
        // )
        //   .then((response) => {
        //     console.log("answer response", response.text());
        //     return response.text();
        //   })
        //   .then((result) => {
        //     console.log(result);
        //   });
      } catch (error) {
        console.log(error);
      }
    },
    setRating: function (rating) {
      if (this.rating === rating) {
        this.rating = 0;
      } else {
        this.rating = rating;
      }
    },
    fill(rating) {
      this.starOver = rating;
    },
  },
  async mounted() {
    //   // await this.getAssessments();
    // await this.assessementStore.fetchAssessments()
    //   this.allTests = await this.assessementStore.getAll
    //   console.log('qst', this.allTests)
    // const data = {
    //   cookie: Cookies.get("candidatecookie"),
    // };https://server.go-platform.com/
    axios
      .post(
        "https://server.go-platform.com/inviteCandidate/candidate",
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.data = response.data;
        this.key1 = response.data.email;
        this.key2 = response.data.date;
        this.key3 = response.data.exp;
        this.key4 = response.data.deadline;
        this.key5 = response.data.ass;
        this.animation = false;
        this.jobPosition = response.data.jobPosition;
        this.company = response.data.company;
        if (response.data.pass) {
          this.passed = 2;
          this.key6 = response.data.creat;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  },
};
</script>

<style lang="scss" scoped>
.window {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-family: "Roboto" !important;
  // padding-top: 3rem;
}

.container {
  font-family: "Poppins";
  font-style: normal;
  padding: 3rem 5rem;
  // padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  .required {
    &::after {
      content: "*";
      color: #ff6969;
    }
  }

  .err_msg {
    color: #ff6969;
    font-size: 12px;
    font-weight: 300;
  }

  .stepper {
    display: flex;
    justify-content: space-between;
    width: 65%;
    position: relative;
    margin: 2rem auto;

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 18%;
      gap: 1rem;

      /*-&-active {
				color:#3361FF;
				background-color: #3361FF;
			}

			&-done {
				background-color: #3361FF;
				color: #3361FF;
                
			}*/
      &-text {
        font-weight: 500;
        font-size: 15.0373px;
        line-height: 23px;
        color: #6b7a99;
        flex-grow: 1;

        &-active {
          color: #2196f3;
        }
      }

      &-number {
        vertical-align: middle;
        display: flex;
        align-items: center;
        background: #6b7a99;
        // z-index: 2;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        line-height: 20px;
        font-size: 16px;
        position: relative;
        border: 1rem solid #fff;

        &::after {
          z-index: -1;
          content: "";
          height: 2.82px;
          background: #6b7a99;
          position: absolute;
          margin: 0 2rem;
          width: 13vw;
          border-radius: 50px;
        }

        &-done {
          background-color: #2196f3;
          color: #2196f3;
          display: flex;
          align-items: center;

          &::after {
            z-index: -1;
            content: "";
            height: 2.82px;
            background: #2196f3;
            position: absolute;
            margin: 0 2rem;
            width: 13vw;
            border-radius: 50px;
          }
        }

        &-active {
          color: #2196f3;
          background-color: #2196f3;
          box-shadow: 0 0 0 1rem #eff0f7;
        }
      }
    }

    > :last-child {
      .step-number::after {
        display: none;
      }
    }
  }

  .first_page {
    display: flex;
    flex-direction: column;
    /*align-items: center;
    gap: 3rem;*/
    padding: 20px 29px;
    background: white;
    border-radius: 12px;
    width: 90%;

    > :first-child {
      font-weight: 700;
      font-size: 28px;
      //line-height: 94px;
      text-align: center;
      color: #2196f3;
    }

    /*> :nth-child(2) {
      display: flex;
      justify-content: space-between;

      .card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        color: #000;
        background: #fff;
        flex: 0 0 25%;
        gap: 0.5rem;
        padding: 2rem 0.5rem;
        box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        > :nth-child(2) {
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          text-align: center;
        }

        > :nth-child(3) {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 0 0.5rem;
        }
      }
    }*/
  }

  .second_page {
    background: #fff;
    border-radius: 10px;
    // margin-top: 3rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    // > :first-child {
    //   font-weight: 500;
    //   font-size: 22px;
    //   line-height: 33px;
    //   color: #2196f3;
    // }

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .input_group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        height: fit-content;

        input {
          border: 1px solid #bdbdbd;
          border-radius: 4px;
          height: 3rem;
          width: 100%;
          box-sizing: border-box;
        }
      }

      .input_group2 {
        display: flex;
        justify-content: space-between;
        gap: 5rem;
        box-sizing: border-box;

        div {
          width: 100%;
        }
      }

      .input_group3 {
        display: flex;
        align-items: center;
        gap: 1rem;
        box-sizing: border-box;
      }
    }
  }

  .third_page {
    width: 60%;
    background: #fff;
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    line-height: 28px;

    > :first-child {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
    }

    > :nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      border: 1px solid #bdbdbd;
      border-radius: 5px;
    }

    > :nth-child(3) {
      a {
        color: #2196f3;
        text-decoration-line: none;
      }
    }

    > :nth-child(4) {
      display: flex;
      flex-direction: row;
    }
  }

  .fourth_page {
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;

    > :first-child {
      border: 3px solid #2196f3;
      border-radius: 50%;
      color: #2196f3;
      font-size: 48px;
      font-weight: 900;
      width: 5rem;
      height: 5rem;
      text-align: center;
    }

    > :nth-child(2) {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    }

    > :nth-child(3) {
      font-weight: 700;
      font-size: 50px;
      line-height: 50px;
      text-align: center;
    }
  }

  .rating_page {
    margin-top: 5%;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    > :first-child {
      padding: 2%;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;

      > :first-child {
        font-weight: 600;
        font-size: 41px;
        line-height: 55px;
        text-align: center;
        color: #2196f3;
        width: 80%;
      }

      > :nth-child(2) {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #bbbbbb;
      }

      > :nth-child(4) {
        resize: none;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #0000004f;
        border-radius: 20px;
        padding: 2%;
      }

      .rating {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 1.5rem;
      }

      .rating span {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 25px;
        z-index: 2;
      }

      .rating span:before {
        content: "\2605";
        position: absolute;
        color: #2196f3;
        font-size: 43px;
        z-index: -1;
      }

      .rating span:hover {
        color: #2196f3;
      }

      .filled {
        color: #2196f3 !important;
      }
    }
  }

  .more_jobs_btn {
    background: #2196f3;
    color: #fff;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    border: none;
    border-radius: 7px;
    margin: 3rem;
    padding: 0.5rem 2rem;
  }

  .result_page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin: 0;
    }
  }

  .page_width {
    width: 65%;
    padding: 3%;
  }

  .slide-fade-enter-active {
    transition: all 0.6s ease-in-out;
  }

  .slide-fade-leave-active {
    display: none;
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(200px);
    opacity: 0;
  }
}

//media query
@media (max-width: 540px) {
  .container {
    font-family: "Poppins";
    font-style: normal;
    //padding: 3rem 5rem;
    // padding-top: 0;
    padding: 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100vh;

    .required {
      &::after {
        content: "*";
        color: #ff6969;
      }
    }

    .err_msg {
      color: #ff6969;
      font-size: 12px;
      font-weight: 300;
    }

    .stepper {
      display: flex;
      justify-content: space-between;
      width: 65%;
      position: relative;
      margin: 2rem auto;

      .step {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 18%;
        gap: 1rem;

        /*-&-active {
				color:#3361FF;
				background-color: #3361FF;
			}

			&-done {
				background-color: #3361FF;
				color: #3361FF;
                
			}*/
        &-text {
          font-weight: 500;
          font-size: 15.0373px;
          line-height: 23px;
          color: #6b7a99;
          flex-grow: 1;

          &-active {
            color: #2196f3;
          }
        }

        &-number {
          vertical-align: middle;
          display: flex;
          align-items: center;
          background: #6b7a99;
          // z-index: 2;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          line-height: 20px;
          font-size: 16px;
          position: relative;
          border: 1rem solid #fff;

          &::after {
            z-index: -1;
            content: "";
            height: 2.82px;
            background: #6b7a99;
            position: absolute;
            margin: 0 2rem;
            width: 13vw;
            border-radius: 50px;
          }

          &-done {
            background-color: #2196f3;
            color: #2196f3;
            display: flex;
            align-items: center;

            &::after {
              z-index: -1;
              content: "";
              height: 2.82px;
              background: #2196f3;
              position: absolute;
              margin: 0 2rem;
              width: 13vw;
              border-radius: 50px;
            }
          }

          &-active {
            color: #2196f3;
            background-color: #2196f3;
            box-shadow: 0 0 0 1rem #eff0f7;
          }
        }
      }

      > :last-child {
        .step-number::after {
          display: none;
        }
      }
    }

    .first_page {
      display: flex;
      flex-direction: column;
      /*align-items: center;
    gap: 3rem;*/
      padding: 20px 29px;
      background: white;
      border-radius: 12px;
      width: 95%;

      > :first-child {
        font-weight: 700;
        font-size: 28px;
        //line-height: 94px;
        text-align: center;
        color: #2196f3;
      }

      /*> :nth-child(2) {
      display: flex;
      justify-content: space-between;

      .card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        color: #000;
        background: #fff;
        flex: 0 0 25%;
        gap: 0.5rem;
        padding: 2rem 0.5rem;
        box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        > :nth-child(2) {
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          text-align: center;
        }

        > :nth-child(3) {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 0 0.5rem;
        }
      }
    }*/
    }

    .second_page {
      background: #fff;
      border-radius: 10px;
      // margin-top: 3rem;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      // > :first-child {
      //   font-weight: 500;
      //   font-size: 22px;
      //   line-height: 33px;
      //   color: #2196f3;
      // }

      form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;

        .input_group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          height: fit-content;

          input {
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 3rem;
            width: 100%;
            box-sizing: border-box;
          }
        }

        .input_group2 {
          display: flex;
          justify-content: space-between;
          gap: 5rem;
          box-sizing: border-box;

          div {
            width: 100%;
          }
        }

        .input_group3 {
          display: flex;
          align-items: center;
          gap: 1rem;
          box-sizing: border-box;
        }
      }
    }

    .third_page {
      width: 60%;
      background: #fff;
      padding: 2rem;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      line-height: 28px;

      > :first-child {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
      }

      > :nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
      }

      > :nth-child(3) {
        a {
          color: #2196f3;
          text-decoration-line: none;
        }
      }

      > :nth-child(4) {
        display: flex;
        flex-direction: row;
      }
    }

    .fourth_page {
      display: flex;
      flex-direction: column;
      background: #fff;
      align-items: center;
      gap: 2rem;
      border-radius: 20px;

      > :first-child {
        border: 3px solid #2196f3;
        border-radius: 50%;
        color: #2196f3;
        font-size: 48px;
        font-weight: 900;
        width: 5rem;
        height: 5rem;
        text-align: center;
      }

      > :nth-child(2) {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
      }

      > :nth-child(3) {
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        text-align: center;
      }
    }

    .rating_page {
      margin-top: 5%;
      padding: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      > :first-child {
        padding: 2%;
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;

        > :first-child {
          font-weight: 600;
          font-size: 41px;
          line-height: 55px;
          text-align: center;
          color: #2196f3;
          width: 80%;
        }

        > :nth-child(2) {
          font-weight: 400;
          font-size: 22px;
          line-height: 33px;
          color: #bbbbbb;
        }

        > :nth-child(4) {
          resize: none;
          outline: none;
          border: none;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #0000004f;
          border-radius: 20px;
          padding: 2%;
        }

        .rating {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          gap: 1.5rem;
        }

        .rating span {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 25px;
          z-index: 2;
        }

        .rating span:before {
          content: "\2605";
          position: absolute;
          color: #2196f3;
          font-size: 43px;
          z-index: -1;
        }

        .rating span:hover {
          color: #2196f3;
        }

        .filled {
          color: #2196f3 !important;
        }
      }
    }

    .more_jobs_btn {
      background: #2196f3;
      color: #fff;
      font-weight: 500;
      font-size: 25px;
      line-height: 38px;
      border: none;
      border-radius: 7px;
      margin: 3rem;
      padding: 0.5rem 2rem;
    }

    .result_page {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        margin: 0;
      }
    }

    .page_width {
      width: 65%;
      padding: 3%;
    }

    .slide-fade-enter-active {
      transition: all 0.6s ease-in-out;
    }

    .slide-fade-leave-active {
      display: none;
      transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateX(200px);
      opacity: 0;
    }
  }
}

.btns_ {
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  margin-top: 3rem;
}

.next_btn {
  background: #2196f3;
  color: #fff;
  border-radius: 7px;
  border: none;
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  width: 7em;
  //margin-top: 3rem;
  // position: absolute;
  // top: 95%;
  cursor: pointer;
}

.loading-btn {
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.next_btn:hover {
  opacity: 0.85;
}

.back_btn {
  background: #fff;
  color: #2196f3;
  border-radius: 7px;
  border: none;
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  width: 7em;
  //margin-top: 3rem;
  // position: absolute;
  top: 95%;
  cursor: pointer;
}

.disabled {
  background: #2196f3;
  cursor: not-allowed;
}

.qst_stepper {
  display: flex;
  justify-content: space-between;
  width: 65%;
  position: relative;

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 18%;
    gap: 1rem;

    &-number {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 20px;
      font-size: 16px;
      position: relative;
      background: #6b7a99;
      color: #fff;
      box-shadow: 0 0 0 1rem #eff0f7;

      &::after {
        z-index: -1;
        content: "";
        height: 2.82px;
        background: #6b7a99;
        position: absolute;
        border-radius: 50px;
        left: calc(100%);
        width: calc(100% * 6);
      }

      &-done {
        background-color: #2196f3;
        color: #fff;
        display: flex;
        align-items: center;
        box-shadow: none;

        &::after {
          z-index: -1;
          content: "";
          height: 2.82px;
          background: #2196f3;
          position: absolute;
          border-radius: 50px;
          left: calc(100%);
          width: calc(100% * 6);
        }
      }

      &-active {
        color: #fff;
        background-color: #2196f3;
        box-shadow: 0 0 0 1rem #fff;
      }
    }
  }

  > :last-child {
    .step-number::after {
      display: none;
    }
  }
}

input[type="file"] {
  padding: 4px;
  margin: -4px;
  position: relative;
  outline: none;
  width: 100%;

  /* File Selector Button Styles */
  &::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(#000, 0.16);
    box-shadow: 0px 1px 0px rgba(#000, 0.05);
    margin-right: 16px;
    width: 25%;
    /*
      This is a hack to change the button label. 
      I'm hiding the default label and then 
      manually applying the width based on 
      updated icon and label.
    */
    // width: 132px;
    color: transparent;

    /*
      Firefox doesn't support the pseudo ::before 
      or ::after elements on this input field so 
      we need to use the @supports rule to enable 
      default styles fallback for Firefox.
    */
    @supports (-moz-appearance: none) {
      color: var(--primary-color);
    }

    &:hover {
      background-color: #f3f4f6;
    }

    &:active {
      background-color: #e5e7eb;
    }
  }

  /* Faked label styles and icon */
  &::before {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232196f3'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }

  &::after {
    position: absolute;
    pointer-events: none;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    content: "Upload Photo (.png)";
  }

  /* Handle Component Focus */
  &:focus-within::file-selector-button,
  &:focus::file-selector-button {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
}

.input_file {
  margin-top: 1rem;
  border: solid 1px #bdbdbd;
  border-radius: 4px;
  padding: 0.25rem;
}

.intructions {
  margin-bottom: 20px;
  font-size: 15px;
}

.loader {
  border: 4px solid #00aef0;
  border-left-color: transparent;
  width: 30px;
  height: 30px;
  animation: spin89345-40df972a 1s linear infinite;
  border-radius: 50%;
}
</style>
