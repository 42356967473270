<template>
  <div class="shareResultsWrapper">
    <div>
      <h2>Share Your excitement</h2>
      <form class="form">
        <img
          :src="`https://server.go-platform.com/certificate/candidateCertificateImg?fullName=${this.assessementStore.fullName}`"
          alt="" />
        <textarea v-model="postContent" placeholder="Write your post here"></textarea>
      </form>
      <div class="btnsWrapper">
        <button class="skipBtn" @click="nextSection">Skip</button>
        <button class="skipBtn" @click="downloadPdf">Download pdf</button>
        <button @click="sharePost" class="submitBtn" type="submit">
          <img src="../assets/icons/linkedin.svg" />
          Share on LinkedIn
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
import axios from "axios";
export default {
  name: "ShareResults",
  data() {
    return {
      postContent: "HEllooooooooooooooooooooooooooooooooooooooooooo",
      certificate: "",
      imgUrl: "",
      textToPost: "",
    };
  },
  props: {
    nextSection: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },

  methods: {
    async sharePost() {
      this.nextSection();
      // Logic to share the post on LinkedIn
      // console.log("Sharing post:", this.postContent);
      // console.log("Sharing certificate:", this.certificate);

      // let config = {
      //   method: "get",
      //   maxBodyLength: Infinity,
      //   url: "",
      //   headers: {
      //     "Content-Type": "text/plain",
      //   },
      // };

      // axios
      //   .request(config)
      //   .then((response) => {
      //     console.log(JSON.stringify(response.data));
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      const url = "https://server.go-platform.com/linkedIn/auth";
      window.open(url, "_blank");
    },

    downloadPdf() {
      let config = {
        method: "get",
        responseType: "blob", // Set the response type to 'blob' for handling binary data (e.g., files)
        url: `https://server.go-platform.com/certificate/candidateCertificate?fullName=${this.assessementStore.fullName}`,
        headers: {
          "Content-Type": "text/plain",
        },
        withCredentials: true,
      };

      axios
        .request(config)
        .then((response) => {
          // Create a Blob from the response data
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.pdf"); // Set the desired file name
          document.body.appendChild(link);
          link.click();

          // Cleanup: remove the temporary URL and the link element
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.imgUrl = `https://server.go-platform.com/certificate/candidateCertificateImg?fullName=Abderraouf`;
    this.textToPost = `I have just completed the`;
  },
};
</script>

<style scoped lang="scss">
textarea {
  width: 99%;
  height: 100px;
  border-radius: 10px;
  padding: 5px;
  resize: vertical;
}

button {
  margin-top: 10px;
}

.shareResultsWrapper {
  //   width: 95vw;
  //   height: 100vh;
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
  }

  > :first-child {
    width: 60%;
    // height: 50%;
    // margin: 0 auto;
    // margin-top: 10%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
  }
}

.form {
  display: flex;
  flex-direction: column;
  //   gap: 10px;
  width: 100%;

  img {
    width: 100%;
    height: fit-content;
  }
}

.btnsWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    margin: 10px;
    font-size: 16px;
    font-weight: 800;
    border-radius: 5px;
    border: none;
    background-color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .skipBtn {
    background-color: #fff;
    color: #2196f3;
    border: #2196f3 1px solid;
  }

  .submitBtn {
    color: #fff;

    padding: 5px;

    img {
      margin: 5px;
      width: 25px;
    }
  }
}
</style>
